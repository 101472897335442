// export const BASE_URL_BDLAW = 'https://reactnative.bdlawservice.com';
// export const BASE_URL_SIDDIQUE = 'https://reactnative.bdlawservice.com';
// export const BASE_URL_SIDDIQUE_ADMIN = 'https://admin.bdlawservice.com';
// export const BASE_URL_ASP = "https://aspapi.bdlawservice.com";
// export const runningServer = '1';

//export const BASE_URL_SIDDIQUE = 'https://reactnative.bdlawservice.com';
//export const BASE_URL_SIDDIQUE_ADMIN = 'https://admin.bdlawservice.com';


//USA Server
export const BASE_URL_BDLAW = 'https://reactnative.siddiqueenterprise.com';
export const BASE_URL_SIDDIQUE = 'https://reactnative.siddiqueenterprise.com';
export const BASE_URL_SIDDIQUE_ADMIN = 'https://admin.siddiqueenterprise.com';
export const BASE_URL_ASP = "https://aspapi.siddiqueenterprise.com";
export const runningServer = '2';

export const BnYear=()=>{

    const currentYear = new Date().getFullYear()-4;

    const bengaliDigits = currentYear.toString().slice(2,4).replace(/\d/g, function(digit) {
        const bengaliNumerals = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];
        return bengaliNumerals[digit];
    });

    return bengaliDigits;
  }


